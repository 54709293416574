import React, { useLayoutEffect, useState } from "react";
import { X } from "react-feather";
import { Card, Col, Modal, ModalBody, Row } from "reactstrap";
import { demoChartAndroidUrl, demoChartIosUrl } from "../assets/constant.js";
import android from "../assets/images/logo/android.svg";
import apple from "../assets/images/logo/apple.svg";
import downloadApp from "../assets/images/logo/download-app.svg";
import { labels } from "../assets/labels";
import { checkDevice } from "../configs/utils";

const ModalForAMobile = () => {
  const [modalShow, setModalShow] = useState(false);
  const isMobileApp = JSON.parse(localStorage.getItem("isMobileApp"));

  const intervalId = setInterval(() => {
    setModalShow([null, undefined]?.includes(isMobileApp) ? true : false);
  }, 1000);

  useLayoutEffect(() => {
    return () => clearInterval(intervalId);
  }, []);

  const handleClosModal = () => {
    setModalShow(false);
    localStorage.setItem("isMobileApp", false);

    setModalShow(false);
  };

  return checkDevice() ? (
    <>
      <Modal isOpen={modalShow} centered>
        <ModalBody className="p-0 m-0">
          <Card className="bg-primary pt-2 position-relative mb-0 border border-white">
            <div className="d-flex align-items-center justify-content-end p-1 position-absolute w-100 top-0 end-0">
              <X
                color="white"
                className="cursor-pointer"
                onClick={handleClosModal}
              />
            </div>
            <Row>
              <Col>
                <div className="text-center">
                  <span className="text-white fs-26 font fw-bolder">
                    {labels.downloadOurApp1}
                  </span>
                </div>
                <div className="text-center">
                  <span className="text-white fs-26">
                    {labels.forTheBestExperience}
                  </span>
                </div>
                <div className="text-center pt-2 pb-1">
                  <span className="text-white fs-16">
                    {labels.downloadAppLorem}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="w-100 d-flex justify-content-center gap-1 px-2">
                  <a href={demoChartAndroidUrl} target="_blank">
                    <button className="android font">
                      <img src={android} />
                      <span className="align-middle ms-2">
                        {labels.android}
                      </span>
                    </button>
                  </a>

                  <a href={demoChartIosUrl} target="_blank">
                    <button className="ios font">
                      <img src={apple} />
                      <span className="align-middle ms-2">{labels.IOS}</span>
                    </button>
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <img src={downloadApp} className="w-50" />
                </div>
              </Col>
            </Row>
          </Card>
        </ModalBody>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default ModalForAMobile;
