import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import navbar from "../navbar";
import layout from "../layout";
import auth from "../authentication";
import DemoReducer from "./demoReducer";

export default combineReducers({
  userAuth: AuthReducer,
  demo: DemoReducer, 
  auth,
  navbar,
  layout,
});
