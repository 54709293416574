import { labels } from "../labels";

export const demoChartTableColumn = [
  {
    columnName: "#",
  },
  {
    columnName: "demos",
  },
  // {
  //   columnName: "days on chart",
  // },
  // {
  //   columnName: labels.votes,
  // },
];

export const chartTableColumn = [
  {
    columnName: "#",
  },
  {
    columnName: "demos",
  },
  {
    columnName: "action",
  },
  // {
  //   columnName: "days on chart",
  // },
  // {
  //   columnName: labels.votes,
  // },
];

export const myDemoTableColumn = [
  {
    columnName: "#",
  },
  {
    columnName: "demos",
  },
  
  // {
  //   columnName: "days on chart",
  // },
  // {
  //   columnName: labels.upVoteFactor,
  // },
];
export const recentUploadTableColumn = [
  {
    columnName: "#",
  },
  {
    columnName: "demos",
  },
  // {
  //   columnName: labels.upVoteFactor,
  // },
];
export const myDemosTableColumn = [
  {
    columnName: "#",
  },
  {
    columnName: "demos",
  },
  {
    columnName: "creators",
  },
  {
    columnName: "status",
  },
  // {
  //   columnName: "days on chart",
  // },
  // {
  //   columnName: labels.upVoteFactor,
  // },
  // {
  //   columnName: "mark Sold",
  // },
  {
    columnName: "action",
  },
];

export const claimedDemosTableColumn = [
  {
    columnName: "#",
  },
  {
    columnName: "demos",
  },
  {
    columnName: "creators",
  },
  // {
  //   columnName: "tag",
  // },
  // {
  //   columnName: "days on chart",
  // },
  {
    columnName: "action",
  },
];

export const countryListAllIsoData = [
  { name: "Afghanistan", value: "Afghanistan", label: "Afghanistan" },
  { name: "Albania", value: "Albania", label: "Albania" },
  { name: "Algeria", value: "Algeria", label: "Algeria" },
  { name: "American Samoa", value: "American Samoa", label: "American Samoa" },
  { name: "Andorra", value: "Andorra", label: "Andorra" },
  { name: "Angola", value: "Angola", label: "Angola" },
  { name: "Anguilla", value: "Anguilla", label: "Anguilla" },
  { name: "Antarctica", value: "Antarctica", label: "Antarctica" },
  {
    name: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
  },
  { name: "Argentina", value: "Argentina", label: "Argentina" },
  { name: "Armenia", value: "Armenia", label: "Armenia" },
  { name: "Aruba", value: "Aruba", label: "Aruba" },
  { name: "Australia", value: "Australia", label: "Australia" },
  { name: "Austria", value: "Austria", label: "Austria" },
  { name: "Azerbaijan", value: "Azerbaijan", label: "Azerbaijan" },
  { name: "Bahamas (the)", value: "Bahamas (the)", label: "Bahamas (the)" },
  { name: "Bahrain", value: "Bahrain", label: "Bahrain" },
  { name: "Bangladesh", value: "Bangladesh", label: "Bangladesh" },
  { name: "Barbados", value: "Barbados", label: "Barbados" },
  { name: "Belarus", value: "Belarus", label: "Belarus" },
  { name: "Belgium", value: "Belgium", label: "Belgium" },
  { name: "Belize", value: "Belize", label: "Belize" },
  { name: "Benin", value: "Benin", label: "Benin" },
  { name: "Bermuda", value: "Bermuda", label: "Bermuda" },
  { name: "Bhutan", value: "Bhutan", label: "Bhutan" },
  {
    name: "Bolivia (Plurinational State of)",
    value: "Bolivia (Plurinational State of)",
    label: "Bolivia (Plurinational State of)",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
    label: "Bonaire, Sint Eustatius and Saba",
  },
  {
    name: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
  },
  { name: "Botswana", value: "Botswana", label: "Botswana" },
  { name: "Bouvet Island", value: "Bouvet Island", label: "Bouvet Island" },
  { name: "Brazil", value: "Brazil", label: "Brazil" },
  {
    name: "British Indian Ocean Territory (the)",
    value: "British Indian Ocean Territory (the)",
    label: "British Indian Ocean Territory (the)",
  },
  {
    name: "Brunei Darussalam",
    value: "Brunei Darussalam",
    label: "Brunei Darussalam",
  },
  { name: "Bulgaria", value: "Bulgaria", label: "Bulgaria" },
  { name: "Burkina Faso", value: "Burkina Faso", label: "Burkina Faso" },
  { name: "Burundi", value: "Burundi", label: "Burundi" },
  { name: "Cabo Verde", value: "Cabo Verde", label: "Cabo Verde" },
  { name: "Cambodia", value: "Cambodia", label: "Cambodia" },
  { name: "Cameroon", value: "Cameroon", label: "Cameroon" },
  { name: "Canada", value: "Canada", label: "Canada" },
  {
    name: "Cayman Islands (the)",
    value: "Cayman Islands (the)",
    label: "Cayman Islands (the)",
  },
  {
    name: "Central African Republic (the)",
    value: "Central African Republic (the)",
    label: "Central African Republic (the)",
  },
  { name: "Chad", value: "Chad", label: "Chad" },
  { name: "Chile", value: "Chile", label: "Chile" },
  { name: "China", value: "China", label: "China" },
  {
    name: "Christmas Island",
    value: "Christmas Island",
    label: "Christmas Island",
  },
  {
    name: "Cocos (Keeling) Islands (the)",
    value: "Cocos (Keeling) Islands (the)",
    label: "Cocos (Keeling) Islands (the)",
  },
  { name: "Colombia", value: "Colombia", label: "Colombia" },
  { name: "Comoros (the)", value: "Comoros (the)", label: "Comoros (the)" },
  {
    name: "Congo (the Democratic Republic of the)",
    value: "Congo (the Democratic Republic of the)",
    label: "Congo (the Democratic Republic of the)",
  },
  { name: "Congo (the)", value: "Congo (the)", label: "Congo (the)" },
  {
    name: "Cook Islands (the)",
    value: "Cook Islands (the)",
    label: "Cook Islands (the)",
  },
  { name: "Costa Rica", value: "Costa Rica", label: "Costa Rica" },
  { name: "Croatia", value: "Croatia", label: "Croatia" },
  { name: "Cuba", value: "Cuba", label: "Cuba" },
  { name: "Curaçao", value: "Curaçao", label: "Curaçao" },
  { name: "Cyprus", value: "Cyprus", label: "Cyprus" },
  { name: "Czechia", value: "Czechia", label: "Czechia" },
  { name: "Côte d'Ivoire", value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { name: "Denmark", value: "Denmark", label: "Denmark" },
  { name: "Djibouti", value: "Djibouti", label: "Djibouti" },
  { name: "Dominica", value: "Dominica", label: "Dominica" },
  {
    name: "Dominican Republic (the)",
    value: "Dominican Republic (the)",
    label: "Dominican Republic (the)",
  },
  { name: "Ecuador", value: "Ecuador", label: "Ecuador" },
  { name: "Egypt", value: "Egypt", label: "Egypt" },
  { name: "El Salvador", value: "El Salvador", label: "El Salvador" },
  {
    name: "Equatorial Guinea",
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
  },
  { name: "Eritrea", value: "Eritrea", label: "Eritrea" },
  { name: "Estonia", value: "Estonia", label: "Estonia" },
  { name: "Eswatini", value: "Eswatini", label: "Eswatini" },
  { name: "Ethiopia", value: "Ethiopia", label: "Ethiopia" },
  {
    name: "Falkland Islands (the) [Malvinas]",
    value: "Falkland Islands (the) [Malvinas]",
    label: "Falkland Islands (the) [Malvinas]",
  },
  {
    name: "Faroe Islands (the)",
    value: "Faroe Islands (the)",
    label: "Faroe Islands (the)",
  },
  { name: "Fiji", value: "Fiji", label: "Fiji" },
  { name: "Finland", value: "Finland", label: "Finland" },
  { name: "France", value: "France", label: "France" },
  { name: "French Guiana", value: "French Guiana", label: "French Guiana" },
  {
    name: "French Polynesia",
    value: "French Polynesia",
    label: "French Polynesia",
  },
  {
    name: "French Southern Territories (the)",
    value: "French Southern Territories (the)",
    label: "French Southern Territories (the)",
  },
  { name: "Gabon", value: "Gabon", label: "Gabon" },
  { name: "Gambia (the)", value: "Gambia (the)", label: "Gambia (the)" },
  { name: "Georgia", value: "Georgia", label: "Georgia" },
  { name: "Germany", value: "Germany", label: "Germany" },
  { name: "Ghana", value: "Ghana", label: "Ghana" },
  { name: "Gibraltar", value: "Gibraltar", label: "Gibraltar" },
  { name: "Greece", value: "Greece", label: "Greece" },
  { name: "Greenland", value: "Greenland", label: "Greenland" },
  { name: "Grenada", value: "Grenada", label: "Grenada" },
  { name: "Guadeloupe", value: "Guadeloupe", label: "Guadeloupe" },
  { name: "Guam", value: "Guam", label: "Guam" },
  { name: "Guatemala", value: "Guatemala", label: "Guatemala" },
  { name: "Guernsey", value: "Guernsey", label: "Guernsey" },
  { name: "Guinea", value: "Guinea", label: "Guinea" },
  { name: "Guinea-Bissau", value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { name: "Guyana", value: "Guyana", label: "Guyana" },
  { name: "Haiti", value: "Haiti", label: "Haiti" },
  {
    name: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
  },
  { name: "Holy See (the)", value: "Holy See (the)", label: "Holy See (the)" },
  { name: "Honduras", value: "Honduras", label: "Honduras" },
  { name: "Hong Kong", value: "Hong Kong", label: "Hong Kong" },
  { name: "Hungary", value: "Hungary", label: "Hungary" },
  { name: "Iceland", value: "Iceland", label: "Iceland" },
  { name: "India", value: "India", label: "India" },
  { name: "Indonesia", value: "Indonesia", label: "Indonesia" },
  {
    name: "Iran (Islamic Republic of)",
    value: "Iran (Islamic Republic of)",
    label: "Iran (Islamic Republic of)",
  },
  { name: "Iraq", value: "Iraq", label: "Iraq" },
  { name: "Ireland", value: "Ireland", label: "Ireland" },
  { name: "Isle of Man", value: "Isle of Man", label: "Isle of Man" },
  { name: "Israel", value: "Israel", label: "Israel" },
  { name: "Italy", value: "Italy", label: "Italy" },
  { name: "Jamaica", value: "Jamaica", label: "Jamaica" },
  { name: "Japan", value: "Japan", label: "Japan" },
  { name: "Jersey", value: "Jersey", label: "Jersey" },
  { name: "Jordan", value: "Jordan", label: "Jordan" },
  { name: "Kazakhstan", value: "Kazakhstan", label: "Kazakhstan" },
  { name: "Kenya", value: "Kenya", label: "Kenya" },
  { name: "Kiribati", value: "Kiribati", label: "Kiribati" },
  {
    name: "Korea (the Democratic People's Republic of)",
    value: "Korea (the Democratic People's Republic of)",
    label: "Korea (the Democratic People's Republic of)",
  },
  {
    name: "Korea (the Republic of)",
    value: "Korea (the Republic of)",
    label: "Korea (the Republic of)",
  },
  { name: "Kuwait", value: "Kuwait", label: "Kuwait" },
  { name: "Kyrgyzstan", value: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    name: "Lao People's Democratic Republic (the)",
    value: "Lao People's Democratic Republic (the)",
    label: "Lao People's Democratic Republic (the)",
  },
  { name: "Latvia", value: "Latvia", label: "Latvia" },
  { name: "Lebanon", value: "Lebanon", label: "Lebanon" },
  { name: "Lesotho", value: "Lesotho", label: "Lesotho" },
  { name: "Liberia", value: "Liberia", label: "Liberia" },
  { name: "Libya", value: "Libya", label: "Libya" },
  { name: "Liechtenstein", value: "Liechtenstein", label: "Liechtenstein" },
  { name: "Lithuania", value: "Lithuania", label: "Lithuania" },
  { name: "Luxembourg", value: "Luxembourg", label: "Luxembourg" },
  { name: "Macao", value: "Macao", label: "Macao" },
  { name: "Madagascar", value: "Madagascar", label: "Madagascar" },
  { name: "Malawi", value: "Malawi", label: "Malawi" },
  { name: "Malaysia", value: "Malaysia", label: "Malaysia" },
  { name: "Maldives", value: "Maldives", label: "Maldives" },
  { name: "Mali", value: "Mali", label: "Mali" },
  { name: "Malta", value: "Malta", label: "Malta" },
  {
    name: "Marshall Islands (the)",
    value: "Marshall Islands (the)",
    label: "Marshall Islands (the)",
  },
  { name: "Martinique", value: "Martinique", label: "Martinique" },
  { name: "Mauritania", value: "Mauritania", label: "Mauritania" },
  { name: "Mauritius", value: "Mauritius", label: "Mauritius" },
  { name: "Mayotte", value: "Mayotte", label: "Mayotte" },
  { name: "Mexico", value: "Mexico", label: "Mexico" },
  {
    name: "Micronesia (Federated States of)",
    value: "Micronesia (Federated States of)",
    label: "Micronesia (Federated States of)",
  },
  {
    name: "Moldova (the Republic of)",
    value: "Moldova (the Republic of)",
    label: "Moldova (the Republic of)",
  },
  { name: "Monaco", value: "Monaco", label: "Monaco" },
  { name: "Mongolia", value: "Mongolia", label: "Mongolia" },
  { name: "Montenegro", value: "Montenegro", label: "Montenegro" },
  { name: "Montserrat", value: "Montserrat", label: "Montserrat" },
  { name: "Morocco", value: "Morocco", label: "Morocco" },
  { name: "Mozambique", value: "Mozambique", label: "Mozambique" },
  { name: "Myanmar", value: "Myanmar", label: "Myanmar" },
  { name: "Namibia", value: "Namibia", label: "Namibia" },
  { name: "Nauru", value: "Nauru", label: "Nauru" },
  { name: "Nepal", value: "Nepal", label: "Nepal" },
  {
    name: "Netherlands (the)",
    value: "Netherlands (the)",
    label: "Netherlands (the)",
  },
  { name: "New Caledonia", value: "New Caledonia", label: "New Caledonia" },
  { name: "New Zealand", value: "New Zealand", label: "New Zealand" },
  { name: "Nicaragua", value: "Nicaragua", label: "Nicaragua" },
  { name: "Niger (the)", value: "Niger (the)", label: "Niger (the)" },
  { name: "Nigeria", value: "Nigeria", label: "Nigeria" },
  { name: "Niue", value: "Niue", label: "Niue" },
  { name: "Norfolk Island", value: "Norfolk Island", label: "Norfolk Island" },
  {
    name: "Northern Mariana Islands (the)",
    value: "Northern Mariana Islands (the)",
    label: "Northern Mariana Islands (the)",
  },
  { name: "Norway", value: "Norway", label: "Norway" },
  { name: "Oman", value: "Oman", label: "Oman" },
  { name: "Pakistan", value: "Pakistan", label: "Pakistan" },
  { name: "Palau", value: "Palau", label: "Palau" },
  {
    name: "Palestine, State of",
    value: "Palestine, State of",
    label: "Palestine, State of",
  },
  { name: "Panama", value: "Panama", label: "Panama" },
  {
    name: "Papua New Guinea",
    value: "Papua New Guinea",
    label: "Papua New Guinea",
  },
  { name: "Paraguay", value: "Paraguay", label: "Paraguay" },
  { name: "Peru", value: "Peru", label: "Peru" },
  {
    name: "Philippines (the)",
    value: "Philippines (the)",
    label: "Philippines (the)",
  },
  { name: "Pitcairn", value: "Pitcairn", label: "Pitcairn" },
  { name: "Poland", value: "Poland", label: "Poland" },
  { name: "Portugal", value: "Portugal", label: "Portugal" },
  { name: "Puerto Rico", value: "Puerto Rico", label: "Puerto Rico" },
  { name: "Qatar", value: "Qatar", label: "Qatar" },
  {
    name: "Republic of North Macedonia",
    value: "Republic of North Macedonia",
    label: "Republic of North Macedonia",
  },
  { name: "Romania", value: "Romania", label: "Romania" },
  {
    name: "Russian Federation (the)",
    value: "Russian Federation (the)",
    label: "Russian Federation (the)",
  },
  { name: "Rwanda", value: "Rwanda", label: "Rwanda" },
  { name: "Réunion", value: "Réunion", label: "Réunion" },
  {
    name: "Saint Barthélemy",
    value: "Saint Barthélemy",
    label: "Saint Barthélemy",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    value: "Saint Helena, Ascension and Tristan da Cunha",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    name: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
  },
  { name: "Saint Lucia", value: "Saint Lucia", label: "Saint Lucia" },
  {
    name: "Saint Martin (French part)",
    value: "Saint Martin (French part)",
    label: "Saint Martin (French part)",
  },
  {
    name: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
  },
  {
    name: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { name: "Samoa", value: "Samoa", label: "Samoa" },
  { name: "San Marino", value: "San Marino", label: "San Marino" },
  {
    name: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
  },
  { name: "Saudi Arabia", value: "Saudi Arabia", label: "Saudi Arabia" },
  { name: "Senegal", value: "Senegal", label: "Senegal" },
  { name: "Serbia", value: "Serbia", label: "Serbia" },
  { name: "Seychelles", value: "Seychelles", label: "Seychelles" },
  { name: "Sierra Leone", value: "Sierra Leone", label: "Sierra Leone" },
  { name: "Singapore", value: "Singapore", label: "Singapore" },
  {
    name: "Sint Maarten (Dutch part)",
    value: "Sint Maarten (Dutch part)",
    label: "Sint Maarten (Dutch part)",
  },
  { name: "Slovakia", value: "Slovakia", label: "Slovakia" },
  { name: "Slovenia", value: "Slovenia", label: "Slovenia" },
  {
    name: "Solomon Islands",
    value: "Solomon Islands",
    label: "Solomon Islands",
  },
  { name: "Somalia", value: "Somalia", label: "Somalia" },
  { name: "South Africa", value: "South Africa", label: "South Africa" },
  {
    name: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  { name: "South Sudan", value: "South Sudan", label: "South Sudan" },
  { name: "Spain", value: "Spain", label: "Spain" },
  { name: "Sri Lanka", value: "Sri Lanka", label: "Sri Lanka" },
  { name: "Sudan (the)", value: "Sudan (the)", label: "Sudan (the)" },
  { name: "Suriname", value: "Suriname", label: "Suriname" },
  {
    name: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
  },
  { name: "Sweden", value: "Sweden", label: "Sweden" },
  { name: "Switzerland", value: "Switzerland", label: "Switzerland" },
  {
    name: "Syrian Arab Republic",
    value: "Syrian Arab Republic",
    label: "Syrian Arab Republic",
  },
  { name: "Taiwan", value: "Taiwan", label: "Taiwan" },
  { name: "Tajikistan", value: "Tajikistan", label: "Tajikistan" },
  {
    name: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of",
    label: "Tanzania, United Republic of",
  },
  { name: "Thailand", value: "Thailand", label: "Thailand" },
  { name: "Timor-Leste", value: "Timor-Leste", label: "Timor-Leste" },
  { name: "Togo", value: "Togo", label: "Togo" },
  { name: "Tokelau", value: "Tokelau", label: "Tokelau" },
  { name: "Tonga", value: "Tonga", label: "Tonga" },
  {
    name: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
  },
  { name: "Tunisia", value: "Tunisia", label: "Tunisia" },
  { name: "Turkey", value: "Turkey", label: "Turkey" },
  { name: "Turkmenistan", value: "Turkmenistan", label: "Turkmenistan" },
  {
    name: "Turks and Caicos Islands (the)",
    value: "Turks and Caicos Islands (the)",
    label: "Turks and Caicos Islands (the)",
  },
  { name: "Tuvalu", value: "Tuvalu", label: "Tuvalu" },
  { name: "Uganda", value: "Uganda", label: "Uganda" },
  { name: "Ukraine", value: "Ukraine", label: "Ukraine" },
  {
    name: "United Arab Emirates (the)",
    value: "United Arab Emirates (the)",
    label: "United Arab Emirates (the)",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    value: "United Kingdom of Great Britain and Northern Ireland (the)",
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
  },
  {
    name: "United States Minor Outlying Islands (the)",
    value: "United States Minor Outlying Islands (the)",
    label: "United States Minor Outlying Islands (the)",
  },
  {
    name: "United States of America (the)",
    value: "United States of America (the)",
    label: "United States of America (the)",
  },
  { name: "Uruguay", value: "Uruguay", label: "Uruguay" },
  { name: "Uzbekistan", value: "Uzbekistan", label: "Uzbekistan" },
  { name: "Vanuatu", value: "Vanuatu", label: "Vanuatu" },
  {
    name: "Venezuela (Bolivarian Republic of)",
    value: "Venezuela (Bolivarian Republic of)",
    label: "Venezuela (Bolivarian Republic of)",
  },
  { name: "Viet Nam", value: "Viet Nam", label: "Viet Nam" },
  {
    name: "Virgin Islands (British)",
    value: "Virgin Islands (British)",
    label: "Virgin Islands (British)",
  },
  {
    name: "Virgin Islands (U.S.)",
    value: "Virgin Islands (U.S.)",
    label: "Virgin Islands (U.S.)",
  },
  {
    name: "Wallis and Futuna",
    value: "Wallis and Futuna",
    label: "Wallis and Futuna",
  },
  { name: "Western Sahara", value: "Western Sahara", label: "Western Sahara" },
  { name: "Yemen", value: "Yemen", label: "Yemen" },
  { name: "Zambia", value: "Zambia", label: "Zambia" },
  { name: "Zimbabwe", value: "Zimbabwe", label: "Zimbabwe" },
  { name: "Åland Islands", value: "Åland Islands", label: "Åland Islands" },
];

export const demoChartAndroidUrl =
  "https://play.google.com/store/apps/details?id=com.demochart.app&pcampaignid=web_share";
export const demoChartIosUrl =
  "https://apps.apple.com/us/app/demochart/id6445852150";
