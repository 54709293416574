import { ARTIST_DETAIL } from "../constant";

const initialStates = {
  artistDetail: null,
};

export default (state = initialStates, action) => {
  switch (action.type) {
    case ARTIST_DETAIL: {
      return {
        ...state,
        artistDetail: action.payload,
      };
    }
    default:
      return state;
  }
};
