import {
  GET_CHARTS,
  GET_MY_DEMOS,
  GET_UNCLAIMED_DEMOS_COUNT,
} from "../constant";

const initialStates = {
  demos: null,
  unClaimedDemosCount: 0,
};

export default (state = initialStates, action) => {
  switch (action.type) {
    case GET_MY_DEMOS: {
      return {
        ...state,
        demos: action.payload,
      };
    }
    case GET_UNCLAIMED_DEMOS_COUNT: {
      return {
        ...state,
        unClaimedDemosCount: action.payload,
      };
    }
    case GET_CHARTS: {
      return {
        ...state,
        charts: action.payload,
      };
    }
    default:
      return state;
  }
};
