export const labels = {
  demochart: "demochart",
  newToDemoChart: "new to demochart?",
  create: "Create",
  tologin: "to login",
  tags: "alias tags",
  creatortags: "creator tags",
  creatorAccount: "create account",
  passwordReset: "password reset",
  passwordSuccessText: "Your password has been changed successfully.",
  fullName: "full name",
  confirmPassword: "confirm password",
  matchedPassword: "passwords don’t match",
  alreadyHaveAnAccount: "already have an account?",
  no: "no",
  yes: "yes",
  deny: "deny",
  unClaimedDemosText: "unclaimed demos",
  privacyPolicy: "privacy policy",
  setNewPassword: "set new password",
  welcomeToDemoChart: "welcome to demochart",
  endUserLicenseAgreement: "end user license agreement",
  approved: "approved",
  approve: "approve",
  dismiss: "dismiss",
  instrument: "instrument",
  selectDemoStatus: "Select Demo Status",
  selectInstrument: "select instrument",
  notification: "notifications",
  addToFeatured: "add to featured",
  share: "share",
  explicit: "explicit",
  myProfile: "my profile",
  available: "available",
  private: "private",
  sold: "sold",
  enterCompleteProfile: "setup your creator profile",
  featuredDemos: "featured demos",
  selectFeaturedDemo: "select featured demo",
  emailVerified: "email verified",
  emailVerifiedText: "your email has been verified successfully.",
  demoStatus: "demo status",
  twitter: "Twitter",
  roles: "roles",
  markAsSold: "mark as sold",
  dontAppear: "don't appear",
  appearOnProfile: "appear on profile",
  youHaveNoNotificationsData: "you have no notifications data available",
  verifyYourEmailConfirmAccount: [
    "verify your email",
    <br />,
    "to confirm your account",
  ],
  verifyYourEmailConfirmAccountText:
    " we sent a verification link to the email address you used to sign up. please click that link to confirm your account. if you can't see the email, please check your spam or junk folder",
  writer: "Writer",
  producer: "Producer",
  describeHere: "describe here",
  addMoreDemo: "add More Demo",
  claim: "claim",
  help: "help",
  seeAll: "see all",
  accepted: "accepted",
  demoDetails: "demo details",
  noDataFound: "no data found!",
  unClaimedDemos: "you have no unclaimed demos",
  noPlaylistFound: "no playlist available",
  uploadOnlyAudioFileError: "Please upload only audio file!",
  demoFileSizeError: "Demo file size must be less than 1 GB",
  loadMoreNotifications: "Load More Notifications",
  demystify: "demystify",
  demystifyText: `making a living from writing songs has always been an uphill battle as it seems that the industry caters for the 1% that have a track record of previous success. demochart allows creators to build and have control over their own “track record”.`,
  democratize: "democratize",
  democratizeText:
    "gatekeepers of the music industry have existed for decades and they’ve always had immense influence over the songs that top the charts. demochart gives the power back to creators and listeners by providing an environment where listener engagement ultimately decides who comes out on top.",
  hey: "hey, ",
  add: "Add",
  viewAll: "View All",
  wksChart: "days on chart",
  noDemosFound: "no demos found!",
  footerdemochart: "demochart",
  ticket: "Ticket",
  invalidURL: "sorry not a valid url, dont't forget to use https:// or http.",
  upVoteFactor: "upvote factor",
  permission: "permission",
  connectToInstagram: "connect to instagram",
  chooseFilesToUpload: "or choose file to upload",
  dragNDropFiles: "drag and drop your demo here",
  dropFilesHere: "Drop the files here ...",
  oldPassword: "old password",
  newPassword: "new password",
  caption: "caption",
  selectGenre: "select genre",
  typeHere: "Type Here",
  confirmNewPassword: "confirm new password",
  continue: "continue",
  remove: "remove",
  change: "change",
  setting: "settings",
  logOut: "log out",
  votes: "Votes",
  next: "Next",
  anyoneCanUse: "anyone can use",
  emailAddress: "Email Address",
  askForUse: "no, ask for use",
  myDemos: "my demos",
  searchDemos: "search demos",
  areYouSure: "Are you sure?",
  cancel: "cancel",
  yesDelete: "yes, delete",
  addDemoDetails: "add demo details",
  editDemo: "edit demo",
  demoTitle: "demo title",
  genre: "genre",
  demoUsePermission: "demo Use permission",
  description: "Description",
  doYouReallyWantDeleteDemo: "Do you really want to delete this demo.",
  doYouReallyWantDeletePlayList: "Do you really want to delete this playlist.",
  edit: "edit",
  delete: "delete",
  recentUploads: "recent uploads",
  registerNow: "register now",
  verficationSuccessfull: "verification successful",
  enterDetailsToRegister: "enter details to register",
  resetPassword: "reset password",
  ticketSubmission: "help submission",
  serviceEmptyError: "service field can not be empty",
  phoneNumberEmptyError: "phone number field can not be empty",
  issueEmptyError: "issue field can not be empty",
  setFeatureDemo: "+ set featured demo",
  setFeaturePlaylist: "+ set feature playlist",
  phoneNumberValidationText:
    "phone number must be in numeric and  greater than 7 and less than 15.",
  alias: "alias",
  settings: "settings",
  editProfile: "edit profile",
  letsTalk: "let's talk",
  didNotReceive: "did not receive?",
  discoverText:
    "99% of the demos created in the music industry never get ﻿released. Creators are at the mercy of the subjective opinions of managers, publishers and labels while their millions of hours of work remain unexplored and they remain uncompensated.",
  resend: "resend",
  ticketEmail: "email",
  writeHere:
    "write here with go on brag a bit grammy nominated songwriter from sweden etc etc.",
  ourAddress: "Our Address",
  telephone: "telephone",
  telephoneNumber: "(000) 000-0000",
  ticketMail: "gus@demochart.com",
  ticketAddress: "About Kinney BLVD, Venice, CA, 91313",
  submitForm: "submit ticket",
  submit: "Submit",
  yourName: "your name",
  skipNow: "skip now",
  save: "save",
  instagramUrl: "Instagram URL",
  instagramName: "Instagram name",
  instagramNameRequired: ["please enter your instagram name"],
  stillNeedHelp: "still need help? send us a note!",
  helpUrl: "help@demochart.com",
  maxCharaters: "255 max charater",
  fullNameEmptyError: "full name can not be empty",
  cityEmptyError: "city can not be empty",
  stateEmptyError: "state can not be empty",
  countryEmptyError: "country can not be empty",

  aliasEmptyError: "alias can not be empty",
  titleEmptyError: "title can not be empty",
  descriptionEmptyError: "description can not be empty",
  genreEmptyError: "genre can not be empty",
  forgotPasswordText: ["please enter your email address"],
  changePasswordText:
    "your password must be different to previously used passwords.",
  verficationSuccessfullText: [
    "your profile has been submitted & will be",
    <br />,
    "reviewed by our team. You will be notified Thank you.",
  ],
  confirmPasswordEmptyError: "confirm password can not be empty",
  click: "click",
  here: "here",
  back: "back",
  backWithArrow: "< back",
  done: "Done",
  biography: "biography",
  uploadDemos: "upload demos",
  changePassword: "change password",
  toJoinUs: "to join us",
  enterDigitCode: "Enter 4 Digit Code",
  enterDigitCodeText: "The code was sent to your email address,",
  theChart: "The Chart",
  verify: "Verify",
  method: "method",
  mission: "mission",
  discord: "Discord",
  creatorHub: "Creator Hub",
  home: "home",
  about: "about",
  termsConditions: "terms & conditions",
  thusFar: "Thus Far",
  faqs: "faq's",
  contactUs: "Contact Us",
  allRightReserved: ". all rights reserved.",
  letsExplore: "Let's Explore",
  topDemos: "top demos",
  whyDemochart: "why demochart?",
  virality: "virality",
  library: "library",
  passion: "passion",
  musicCreator: "Music Creator",
  topCreators: ["top", <br />, "creators"],
  topCreatorsText: ["the most upvoted creators,", <br />, "updated weekly"],
  topCreatorPara: "Lorem ipsum dolor",
  socialMediaFeed: "social media feed",
  youtube: "Youtube",
  instagram: "Instagram",
  tiktok: "Tiktok",
  downloadOurApp: [<b>download our app</b>, <br />, "for the best experience!"],
  downloadOurApp1: "download our app",
  forTheBestExperience: "for the best experience!",
  subscribeOurLetter: "Subscribe Our Newsletter",
  joinEmailExclusiveMusic: "Join our email list to get exclusive Music.",
  yourEmailAddress: "Your Email Address",
  emailAddress: "email address",
  service: "Service",
  phoneNumber: "phone number",
  whatsIssue: "what is the issue?",
  subscribe: "Subscribe",
  IOS: "iOS",
  android: "Android",
  recentDemos: "recent demos",
  demos: "Demos",
  hash: "#",
  lorem: "Lorem ipsum dolor sit ame",
  creator: "creator",
  listner: "listener",
  howToChart: "how to chart",
  enjoy: "how to enjoy",
  goToWebsite: "go to website",
  welcomeBack: "created for creators",
  takeBackControl: "take back control.",
  timeToTakeBackControl: "time to take back control.",
  emailAddress: "email address",
  password: "Password",
  login: "login",
  demoChartInCnbc: "Demochart in cnbc",
  demoChartInBbc: "Demochart in BBC",
  demoChartInRedbull: "Demochart in RedBull",
  demoChartInNyT: "Demochart in the New York Times",
  forgotPassword: "forgot password",
  loginEmailError: "Please enter correct email",
  loginEmailEmptyError: "Email field can not be empty",
  loginPasswordEmptyError: "Password field can not be empty.",
  newPasswordEmptyError: "New password field can not be empty.",
  oldPasswordEmptyError: "Old password field can not be empty.",
  confirmPasswordEmptyError: "Confirm new password field can not be empty.",
  chooseYourExperience: "choose your experience",
  discover: "discover",
  demoPara:
    "Lorem ipsum dolor ag amet adipisc ag amet adipiscing elit amet adipisc amet adipiscing elitadipiscing elit amet adipisc.",
  downloadAppLorem: "listen anywhere and sync up to other apps",
  thusLorem:
    "Lorem ipsum dolor ag amet adipisc ag amet adipiscing elit amet adipisc amet adipiscing elitadipiscing elit amet adipisc.Lorem ipsum dolor ag amet adipisc ag amet adipiscing elit amet adipisc amet adipiscing elitadipiscing elit amet adipisc",
  passwordValidationText:
    "Password must be at least eight characters length,at least one uppercase letter, one lowercase letter, one number and one special character.",
  viralityText:
    "demochart responds in a way that is a pure reflection of the likes and dislikes of its users. No pay to play. No bureaucracy. Just good demos.",
  libraryText:
    "demochart is built for the creators. It serves as a customisable & organized portfolio of one's work that is easy to share with collaborators and teams.",
  passionText:
    "demochart gives its users the ability to dig into a previously veiled world of music formation. Who will be the first to discover and share the most talented creators.",
  playList: "playlist",
  addNewPlaylist: "add new playlist",
  loading: "loading ...",
  addPlayList: "add playlist",
  editPlayList: "edit playlist",
  title: "title",
  public: "public",
  featuredPlayList: "featured playlists",
  featurePlaylist: "feature playlist",
  selectFeaturedPLayList: "select featured playlist",
  addToPlayList: "add to playlist",
  selectPLayList: "select playlist",
  city: "city",
  state: "state",
  country: "country",
  location: "location",
  searchLocation: "search location",
  searchAddress: "city/state/country",
  address: "address",
  creatorHome: "creator home",
  charts: "charts",
  chart: "chart",
  searchChart: "search chart",
  upVoted: "up voted",
  upVote: "up vote",
  deleteAccount: "Delete Account",
};
